import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

$(document).ready(function () {
  function tabAncor() {
    const profBtn = document.querySelector(".js-prof-tab");
    const clientBtn = document.querySelector(".js-client-tab");

    profBtn?.addEventListener("click", () => {
      scrollToEl("#care|500");
      const profTab = document.querySelector("#ui-id-1");
      profTab.click();
    });

    clientBtn?.addEventListener("click", () => {
      scrollToEl("#care|500");
      const clientTab = document.querySelector("#ui-id-2");
      clientTab.click();
    });
  }
  tabAncor();

  if (document.querySelector(".js-dropdown")) {
    const btns = document.querySelectorAll(".js-dropdown");

    btns.forEach((btn) => {
      const dropdown = btn.querySelector(".header__dropdown");
      let timer;

      btn.addEventListener("click", () => {
        btn.classList.toggle("open");
        dropdown.classList.toggle("open");
      });

      btn.addEventListener("mouseenter", () => {
        clearTimeout(timer);
      });

      btn.addEventListener("mouseleave", () => {
        timer = setTimeout(() => {
          btn.classList.remove("open");
          dropdown.classList.remove("open");
        }, 1000);
      });
    });
  }

  if (document.querySelector(".typed-element")) {
    const typedText = document.querySelectorAll(".typed-text");
    let stringsArr = [];
    typedText.forEach((string) => {
      stringsArr = [...stringsArr, string.innerHTML];
    });
    const typed = new Typed(".typed-element", {
      strings: [...stringsArr],
      typeSpeed: 40,
      backSpeed: 20,
      loop: true,
    });
  }

  $("a").on("click", function (event) {
    if (!event.target.classList.contains("ui-tabs-anchor")) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            window.location.hash = hash;
          }
        );
      }
    }
  });

  AOS.init({
    offset: -250,
    delay: 0,
    duration: 400,
    easing: "ease",
    once: true,
  });

  //tilt
  const tiltElements = document.querySelectorAll(".js-tilt");

  if (tiltElements.length > 0 && window.innerWidth > 1365) {
    VanillaTilt.init(tiltElements, {
      max: 15,
      speed: 1000,
      reset: true,
      transition: true,
      "reset-to-start": true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      glare: true,
      "max-glare": 0.3,
    });
  }
  // end tilt

  // burger-menu
  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
      layoutElem = document.querySelector(".header__layout"),
      burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("open");
      document.body.classList.remove("no-scroll");
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach((i) => {
      i.addEventListener("click", (e) => {
        if (menuElem.classList.contains("open")) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("open")) {
        removeClass();
      } else {
        menuElem.classList.add("open");
        document.body.classList.add("no-scroll");
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
    });

    window.addEventListener("resize", function () {
      if (window.innerWidth > 1200) {
        removeClass();
      }
    });
  };

  burgerMenu(".header", ".header__burger");
  // end burger-menu

  // gsap
  gsap.registerPlugin(ScrollTrigger);
  if (window.innerWidth > 568) {
    let speed = 15;
    if (window.innerWidth > 1023) {
      speed = 20;
    }
    let tl = gsap.timeline();
    const scrollingText = document.querySelectorAll(".muve__text");
    if (scrollingText.length > 0) {
      scrollingText.forEach((text, i) => {
        const val = i % 2 === 0 ? -speed : speed;

        tl.to(text, {
          xPercent: val,
          scrollTrigger: {
            trigger: text,
            scrub: 0,
          },
        });
      });
    }
  }

  ScrollTrigger.create({
    start: "50 bottom",
    trigger: ".muve-svg",
    end: 99999,
    onEnter: () => $(".muve__decor").addClass("anim"),
  });

  // end gsap

  // tabs
  $(".care__tab-box").tabs({
    show: { effect: "fadeIn", duration: 150 },
    hide: { effect: "fadeOut", duration: 150 },
  });
  $(".about__tab-box").tabs({
    show: { effect: "fadeIn", duration: 150 },
    hide: { effect: "fadeOut", duration: 150 },
  });
  // end tabs

  // marquee
  $(".marquee").marquee({
    duration: 30000,
    duplicated: true,
    delayBeforeStart: 100,
    startVisible: true,
  });
  $(".marquee-revers").marquee({
    duration: 30000,
    duplicated: true,
    direction: "right",
    delayBeforeStart: 100,
    startVisible: true,
  });
  // end marquee

  // swiper
  if (document.querySelector(".jobs__slider")) {
    MetaSwiper(`.jobs__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 15,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".jobs__btn-prev",
        nextEl: ".jobs__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 30,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 45,
        },
      },
    });
  }

  // if (document.querySelector(".about__slider--clients")) {
  //   const aboutSlider = new Swiper(`.about__slider--clients`, {
  //     loop: false,
  //     grabCursor: true,
  //     slidesPerView: 1,
  //     spaceBetween: 0,
  //     observer: true,
  //     observeParents: true,
  //     speed: 400,
  //     autoplay: {
  //       delay: 4000,
  //       disableOnInteraction: false,
  //       pauseOnMouseEnter: true,
  //     },
  //     navigation: {
  //       prevEl: ".about__btn-prev--clients",
  //       nextEl: ".about__btn-next--clients",
  //     },
  //     breakpoints: {
  //       658: {
  //         slidesPerView: 2,
  //         spaceBetween: 40,
  //       },
  //       1024: {
  //         slidesPerView: 3,
  //         spaceBetween: 100,
  //       },
  //       1920: {
  //         slidesPerView: 3,
  //         spaceBetween: 150,
  //       },
  //     },
  //   });
  //
  //   document.querySelector("#ui-id-4").addEventListener('click', () => {
  //     setTimeout(() => {
  //       SafariSwiper(aboutSlider)
  //       aboutSlider.autoplay.start()
  //     }, 500)
  //   })
  // }

  if (document.querySelector(".about__slider--prof")) {
    const dropdownContainer = document.querySelector(".js-slider-heads");
    const sliderAncor = document.querySelector(".about__slider--prof");
    const slides = sliderAncor.querySelectorAll(".about__head");
    slides.forEach((slide, i) => {
      const elem = document.createElement("a");
      elem.classList.add("header__link", "pointer");
      elem.textContent = slide.textContent.trim();
      dropdownContainer?.appendChild(elem);
    });

    const aboutSlider = new Swiper(`.about__slider--prof`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".about__btn-prev--prof",
        nextEl: ".about__btn-next--prof",
      },
      breakpoints: {
        658: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 100,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 150,
        },
      },
      on: {
        init: () => {
          const sliderTab = document.querySelector("#ui-id-3");
          const headsText = document.querySelectorAll(".js-slider-heads a");
          headsText.forEach((btn, i) => {
            btn.addEventListener("click", () => {
              sliderTab.click();
              scrollToEl("#about_ancor|500");
              if (
                document.querySelector("body").classList.contains("no-scroll")
              ) {
                document.querySelector("body").classList.remove("no-scroll");
              }
              aboutSlider.slideTo(i);
            });
          });
        },
      },
    });
  }

  if (document.querySelector(".care__slider--1")) {
    MetaSwiper(`.care__slider--1`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".care__btn-prev--1",
        nextEl: ".care__btn-next--1",
      },
      breakpoints: {
        568: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1023: {
          slidesPerView: "auto",
          spaceBetween: 25,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 40,
        },
      },
    });
  }

  if (document.querySelector(".care__slider--2")) {
    const careSlider = new Swiper(`.care__slider--2`, {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".care__btn-prev--2",
        nextEl: ".care__btn-next--2",
      },
      breakpoints: {
        568: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1023: {
          slidesPerView: "auto",
          spaceBetween: 25,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 40,
        },
      },
    });

    document.querySelector("#ui-id-2").addEventListener("click", () => {
      setTimeout(() => {
        SafariSwiper(careSlider);
        careSlider.autoplay.start();
      }, 500);
    });
  }

  if (document.querySelector(".benefits__slider")) {
    MetaSwiper(`.benefits__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 15,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".benefits__btn-prev",
        nextEl: ".benefits__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 40,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 65,
        },
      },
    });
  }
  // end swiper

  // nice-select
  $(".form-work__select").niceSelect();
  $(".callback__select").niceSelect();

  const selectWrap = document.querySelectorAll(".form-work__select .list");

  if (!!selectWrap.length) {
    selectWrap.forEach((select) => new SimpleBar(select, { autoHide: true }));
  }
  // end nice-select
});
